import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 8rem 3rem;
  background: #fcffff;

  @media (max-width: 500px) {
    padding: 8rem 1.5rem;
  }
`;

export const Card = styled.div`
  width: 475px;
  height: 830px;
  background-color: #fcffff;
  box-shadow: 0px 10px 60px rgba(57, 57, 57, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 1.5rem;
  margin: 1.5rem;

  @media (max-width: 1300px) {
    width: 401px;
    height: 705px;
    margin: 1rem;
  }

  @media (max-width: 1000px) {
    width: 349px;
    height: 615px;
  }

  @media (max-width: 500px) {
    width: 285px;
    height: 550px;
  }

  img {
    height: 359px;
    width: auto;
    margin-bottom: 4rem;

    @media (max-width: 1300px) {
      height: 259px;
      margin-bottom: 2rem;
    }

    @media (max-width: 1000px) {
      height: 209px;
    }

    @media (max-width: 500px) {
      height: 165px;
    }
  }

  h2 {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #4d7c8a;
    margin-bottom: 4rem;

    @media (max-width: 1300px) {
      font-size: 19px;
    }

    @media (max-width: 1000px) {
      font-size: 18px;
    }

    @media (max-width: 500px) {
      font-size: 17px;
    }
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #6d6d6d;
    width: 60%;
    height: 10%;

    @media (max-width: 1300px) {
      font-size: 14px;
    }

    @media (max-width: 1000px) {
      font-size: 13px;
      width: 80%;
    }

    @media (max-width: 500px) {
      font-size: 12px;
      width: 90%;
    }
  }
`;

export const Icon = styled.div`
  width: 69px;
  height: 69px;
  background: linear-gradient(89.5deg, #acf1ac -6.19%, #6fcf97 158.69%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.1rem;

  @media (max-width: 1300px) {
    width: 59px;
    height: 59px;
  }

  @media (max-width: 1000px) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 500px) {
    width: 43px;
    height: 43px;
  }

  i {
    color: #376092;
    font-size: 31px;

    @media (max-width: 1300px) {
      font-size: 26px;
    }

    @media (max-width: 1000px) {
      font-size: 22px;
    }

    @media (max-width: 500px) {
      font-size: 19px;
    }
  }
`;
