import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

import {
  Heading,
  Icon,
  Menu,
  Link,
  Links,
  Buttons,
  Button,
  HeadingInfo,
} from "./styles/burger";

export default function Burger({ open, setOpen, children, ...restProps }) {
  return (
    <Icon open={open} onClick={() => setOpen(!open)} {...restProps}>
      <div />
      <div />
      <div />
    </Icon>
  );
}

Burger.Menu = function BurgerMenu({ open, children, ...restProps }) {
  return (
    <Menu open={open} {...restProps}>
      {children}
    </Menu>
  );
};

Burger.Heading = function BurgerHeading({ children, ...restProps }) {
  return (
    <Heading {...restProps}>
      <img src="/images/Logo.png" alt="Logo" />
    </Heading>
  );
};

Burger.HeadingInfo = function BurgerHeadingInfo({
  name,
  email,
  verified,
  children,
  ...restProps
}) {
  return (
    <HeadingInfo {...restProps}>
      <div>
        <h3>{name}</h3>
        <h3>{email}</h3>
        <p>{verified ? "Verified" : "Not Verified"}</p>
        {children}
      </div>
    </HeadingInfo>
  );
};

Burger.Buttons = function BurgerButtons({ children, ...restProps }) {
  return <Buttons {...restProps}>{children}</Buttons>;
};

Burger.Button = function BurgerButton({ to, background, children, ...restProps }) {
  return (
    <ReactRouterLink to={to}>
      <Button background={background} {...restProps}>
        {children}
      </Button>
    </ReactRouterLink>
  );
};

Burger.Links = function BurgerLinks({ children, ...restProps }) {
  return <Links {...restProps}>{children}</Links>;
};

Burger.Link = function BurgerLink({
  setOpen,
  open,
  to,
  children,
  ...restProps
}) {
  return (
    <ReactRouterLink onClick={() => setOpen(!open)} to={to}>
      <Link {...restProps}>{children}</Link>
    </ReactRouterLink>
  );
};
