import styled from "styled-components";

export const Icon = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  color: #1f2421;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  div {
    z-index: 100;
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => (open ? "#6FCF97" : "#376092")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &:first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      margin-left: auto;
      width: 1.5rem;
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }

  @media (min-width: 1101px) {
    display: none;
  }
`;

export const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fcffff;
  width: 30%;
  height: 100vh;
  align-items: center;
  padding: 1.5rem 0;
  padding-top: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ open }) => (open ? "translateX(235%)" : "translateX(335%)")};
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  z-index: 10;
  box-shadow: 0px 10px 60px rgba(57, 57, 57, 0.1);

  @media (max-width: 1000px) {
    width: 100%;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  }

  @media (min-width: 1101px) {
    display: none;
  }
`;

export const Links = styled.div`
  margin-top: 2rem;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;

  @media (min-width: 1500px) {
    height: 50%;
  }
`;

export const Link = styled.a`
  ${"" /* padding: 2rem 0; */}
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #cacaca;
  text-decoration: none;
  transition: all 0.3s ease;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 29px;

    @media (max-width: 400px) {
      font-size: 1rem;
      line-height: 26px;
    }
  }

  i {
    font-size: 25px;
    margin-right: 1rem;

    @media (max-width: 400px) {
      font-size: 22px;
    }
  }

  &:hover {
    color: #6fcf97;
  }
`;

export const Heading = styled.div`
  width: 85%;
  ${"" /* height: 10%; */}
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eef4e9;

  img {
    width: 176px;
    height: auto;
  }
`;

export const HeadingInfo = styled.div`
  width: 85%;
  ${"" /* height: 10%; */}
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eef4e9;

  div {
    flex-direction: column;
    align-items: center;
    cursor: default;

    h3 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 18px;
      color: #376092;
      transition: all 0.5s ease;
      background: #ebf5f2;
      padding: 1rem 1.5rem;
      border-radius: 30px;
      margin: 1rem 0;
      text-align: center;

      @media (max-width: 1600px) {
        font-size: 15px;
      }

      @media (max-width: 1400px) {
        font-size: 14px;
      }
    }

    p {
      font-family: Roboto;
      font-weight: 500;
      font-size: 18px;
      color: ${({ verified }) => (verified ? "red" : "#6fcf97")};
      background: #ebf5f2;
      padding: 1rem 1.5rem;
      border-radius: 30px;
      margin: 1rem 0;
      text-align: center;

      @media (max-width: 1600px) {
        font-size: 13px;
      }

      @media (max-width: 1400px) {
        font-size: 12px;
      }
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background};
  color: #fffffc;
  width: 147px;
  height: 47px;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  box-shadow: 0px 5px 10px rgba(57, 57, 57, 0.15);
  border-radius: 40px;
  margin: 1rem;
  cursor: pointer;
`;
